import React from 'react'

import Layout from '../components/layout404'
import Intro from '../components/index/intro'

export default ({ data }) => {
  return (
    <Layout>
      <Intro />
    </Layout>
  )
}
