import React from 'react'

import Footer from './footer'

export default ({ children }) => (
  <>
    {children}
    <Footer />
    <div id='preloader'>
      <div id='loader' />
    </div>
  </>
)
